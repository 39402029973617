import "magnific-popup";
(function() {
    let image = $('[mfp-type="image"]');

    if (image.length > 0) {
        image.magnificPopup({type: "image"});
    }

    let gallery = $('[mfp-type="gallery"]');
    if (gallery.length > 0) {
        gallery.magnificPopup({
            type: 'image',
            callbacks:
            {
             markupParse: function(template, values, item)
             {
               values.description = item.el.data('description');
             }
           },
            closeOnContentClick: false,
            closeBtnInside: true,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image:     {
                headerFit: true,
                captionFit: true,
                preserveHeaderAndCaptionWidth: false,
                markup:
                '<div class="mfp-figure">'+
                '<figure>'+
                '<header class="mfp-header">'+
                '<div class="mfp-top-bar">'+
                '<div class="mfp-title"></div>'+
                '<div class="mfp-close"></div>'+
                '<div class="mfp-decoration"></div>'+
                '</div>'+
                '</header>'+
                '<section class="mfp-content-container">'+
                '<div class="mfp-img"></div>'+
                '</section>'+
                '<footer class="mfp-footer">'+
                '<figcaption class="mfp-figcaption">'+
                '<div class="mfp-bottom-bar-container">'+
                '<div class="mfp-bottom-bar">'+
                '<div class="mfp-counter"></div>'+
                '<div class="mfp-description pt-2 text-white"></div>'+
                '<div class="mfp-decoration"></div>'+
                '</div>'+
                '</div>'+
                '</figcaption>'+
                '</footer>'+
                '</figure>'+
                '</div>',
              } ,
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                tCounter: '%curr% of %total%',
                preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
            },
            zoom: {
                enabled: true,
                duration: 500 // don't foget to change the duration also in CSS
            }
        });
    }

    let iframe = $('[mfp-type="iframe"]');

    if (iframe.length > 0) {
        iframe.magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    }
    let inline = $('[mfp-type="inline"]');

    if (inline.length > 0) {
        inline.magnificPopup({
            type: 'inline',
            fixedContentPos: true,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: true,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: 'my-mfp-slide-bottom'
        });
    }

})();
