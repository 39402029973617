export class ExternalLinks {
    hostname = document.location.hostname;
    matchRegex = [new RegExp("^https?:\/\/(.*?)" + this.hostname)];
    context = null;

    constructor(selector) {
        this.context = $(selector);
    }

    addLinkTarget() {
        var self = this;
        this.context.find('a').filter('[href^="http://"], [href^="https://"], [href^="www."]').each(function () {
            var anchor = $(this);
            var href = anchor.attr('href');
            var isInternalLink = false;

            for (var i = 0; i < self.matchRegex.length; i++) {
                var regex = self.matchRegex[i];
                if (href.match(regex)) {
                    isInternalLink = true;
                }
            }

            if (!isInternalLink) {
                anchor.attr('target', '_blank').attr('rel', 'noreferrer').addClass('external-link');
            }
        });
    }
}