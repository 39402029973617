import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';

import "./magnificpopup";
import "./bootstrap";
import "./fontawesome";
import "./glide";
import "./popuptext";

import UIBindings from "./ui-bindings";
import { Forms } from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";
import { ShareHandler } from "./share";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();

const forms = new Forms();
forms.validate();


const UIBinding = new UIBindings();
UIBinding.formSubmitButton();


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}

$(document).ready(function() {
  $(window).on("scroll", function() {
    if ($(window).scrollTop() >= 20) { 
      $('#mainNavigtion').addClass('fixed-top');
    } else {
      $('#mainNavigtion').removeClass('fixed-top');
    }
  });

  $( ".cls-world-1" ).click(function() {
    $( "#internationalContact").removeClass('d-none');
    $( "#internationalContact").addClass('d-block');
  });

  $( ".cls-world-1-main" ).click(function() {
    window.location.href = '/contact?country=Global';
  });

  $( "#closeContact" ).click(function() {
    $( "#internationalContact").addClass('d-none');
    $( "#internationalContact").removeClass('d-block');
  });

  // https://codepen.io/semenchenko/pen/ppJJOJ

  // https://www.cssscript.com/flip-element-carousel/
  const galleryContainer = document.querySelector('.gallery-container');
  const galleryControlsContainer = document.querySelector('.gallery-controls');
  const galleryControls = ['previous', 'next'];
  const galleryItems = document.querySelectorAll('.gallery-item');

  class Carousel {
    constructor(container, items, controls) {
      this.carouselContainer = container;
      this.carouselControls = controls;
      this.carouselArray = [...items];
    }

    // Update css classes for gallery
    updateGallery() {
      this.carouselArray.forEach(el => {
        for (let i = 1; i <= 5; i++) {
          el.classList.remove('gallery-item-'+i);
        }

      });

      this.carouselArray.slice(0, 5).forEach((el, i) => {
        el.classList.add(`gallery-item-${i+1}`);
      });
    }

    // Update the current order of the carouselArray and gallery
    setCurrentState(direction) {

      if (direction.className == 'gallery-controls-previous') {
        this.carouselArray.unshift(this.carouselArray.pop());
      } else {
        this.carouselArray.push(this.carouselArray.shift());
      }
      
      this.updateGallery();
    }
  
    // Add a click event listener to trigger setCurrentState method to rearrange carousel
    useControls() {
      const triggers = [...galleryControlsContainer.childNodes];

      triggers.forEach(control => {
        control.addEventListener('click', e => {
          e.preventDefault();
          this.setCurrentState(control);

        });
      });
    }
  }

  const productGallery = new Carousel(galleryContainer, galleryItems, galleryControls);
  productGallery.useControls();

});


  