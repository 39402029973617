import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, faPlay as falPlay, faQuoteLeft as falQuoteLeft, faQuoteRight as falQuoteRight, faPlayCircle as falPlayCircle, faShare as falShare, faClock, faMapMarkerAlt, faClipboardListCheck, faCalendarDay, faCheck as falCheck, faEye as falEye, faAngleRight as falAngleRight, faAngleLeft as falAngleLeft, faGlobe as falGlobe, faLink as falLink, faVideo as falVideo, faQuestionCircle as falQuestionCircle, faTimesCircle as falTimesCircle, faTimes as falTimes, } from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faCircle as farCircle, faChevronUp as farChevronUp, faChevronDown as farChevronDown, faChevronRight as farChevronRight, faChevronLeft as farChevronLeft, faThumbsUp as farThumbsUp, faAngleRight as farAngleRight, faPhone as farPhone, faBookmark as farBookmark, faShareSquare as farShareSquare, faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faChevronLeft, faChevronDown, faCircle as fasCircle, faSearch as fasSearch, faChevronUp as fasChevronUp, faLink as fasLink, faDownload as fasDownload, faEdit as fasEdit, faPlay as fasPlay, faSpinner as fasSpinner, faEye as fasEye, faPlusSquare as fasPlusSquare, faCaretUp, faAngleUp, faEnvelope as fasEnvelope, faPhone, faPaperPlane as fasPaperPlane,faCheck as fasCheck , faSort as fasSort , faTrophy as fasTrophy, faHeart as fasHeart, faPlayCircle as fasPlayCircle, faPeopleCarry as fasPeopleCarry, faPhoneAlt as faPhoneAlt} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faInstagram, faFacebookSquare, faTwitterSquare, faLinkedin, faGooglePlusG, faGooglePlusSquare} from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(
	falEdit, 
	falQuoteLeft, 
	falQuoteRight, 
	falPlayCircle, 
	falShare, 
	faMapMarkerAlt, 
	faClock,
	faClipboardListCheck, 
	faCalendarDay, 
	falCheck, 
	falEye, 
	falAngleRight, 
	falAngleLeft, 
	falGlobe, 
	falLink, 
	falQuestionCircle,
	falVideo, 
	falTimes,
	falTimesCircle
);
// regular
library.add(farSearch, farCircle, farChevronDown, farChevronLeft, farChevronRight, farThumbsUp, farAngleRight,farPhone, farBookmark, farShareSquare, farHeart);
// solid
library.add(faPhoneAlt,faChevronRight, faChevronLeft, faChevronDown,farChevronUp, fasCircle, fasSearch, fasChevronUp, fasLink, fasDownload, fasEdit, fasPlay, fasSpinner, fasEye, fasPlusSquare, faCaretUp, faAngleUp, fasEnvelope, faPhone, fasPaperPlane, fasCheck,fasSort,fasTrophy,fasHeart,fasPlayCircle,fasPeopleCarry);
// brands
library.add(faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faInstagram, faFacebookSquare, faTwitterSquare, faLinkedin, faGooglePlusG, faGooglePlusSquare);

dom.i2svg();
dom.watch();
