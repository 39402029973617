import Glide from '@glidejs/glide';

(function() {
    'use strict';
    const glides = document.getElementsByClassName('glide');

    for(var i = 0; i < glides.length; i++)
    {
        let glide = glides.item(i);
        new Glide(glide, JSON.parse(glide.dataset.glide)).mount();
    }

})();