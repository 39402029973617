document.addEventListener("DOMContentLoaded", function () {
  let wordLinks = document.querySelectorAll(".word-link");
  for (let i = 0; i < wordLinks.length; i++) {
    wordLinks[i].addEventListener("mouseover", function (event) {
      event.preventDefault();
      let meaning = this.getAttribute("data-meaning");

      let buttonId = this.getAttribute("id");

      tippy("#" + buttonId, {
        content: meaning,
        placement: "bottom-start",
        offset: [0, 50],
        arrow: `<svg class="toolkit" viewBox="140 120 300 300" height="500" width="500"><style>svg.toolkit{ position: absolute; top: 0px; margin-top: -160px;}</style><g transform="translate(39.534089 21.25)"><rect width="1" height="30" rx="0" ry="0" transform="translate(109.965915 102.499999)" fill="#007a33" stroke-width="0"/><ellipse rx="0.158133" ry="0.882356" transform="matrix(15.809524 0 0 2.833322 110.465915 132.5)" fill="#007a33" stroke-width="0"/>Sorry, your browser does not support inline SVG.</svg>`,
        theme: "primary",
        animation: 'scale',
      });
    });
  }
});
